//For branded color button
@mixin cas-btn($btn-name, $btn-color, $btn-type:'solid') {
    @if ($btn-type == 'hollow') {
        .button.#{$btn-name} {
            &.hollow {
                border-color: lighten(get-color(#{$btn-color}), 20%);
                background-color: transparent;
                color: get-color(#{$btn-color});
                &:before {
                    color: get-color(#{$btn-color});
                }
                &:hover, &:active, &:focus {
                    border-color: lighten(get-color(#{$btn-color}), 20%);
                    color: lighten(get-color(#{$btn-color}), 20%);
                    &:before {
                        color: lighten(get-color(#{$btn-color}), 20%);
                    }
                }
                &:focus {
                    box-shadow: none;
                    background-color: rgba(get-color(#{$btn-color}), 0.15);
                }
            }
        }
    } @else {
        .button.#{$btn-name} {
            background-color: get-color(#{$btn-color});
            border-color: get-color(#{$btn-color});
            &:before {
                color: get-color(#{$btn-color});
            }
            &:hover, &:active {
                background-color: scale-color(get-color(#{$btn-color}), $lightness: $button-background-hover-lightness);
                border-color: scale-color(get-color(#{$btn-color}), $lightness: $button-background-hover-lightness);

            }
            &:focus {
                outline: 2px solid transparent;
                box-shadow: inset 0 0 0 1.5px $white, 0 0 0 1.5px lighten(get-color(#{$btn-color}), 5%);
            }
        }
    }
}

//For custom color button (not included in branded color palette)
@mixin custom-btn($btn-name, $btn-color, $btn-type: 'solid') {
    @if ($btn-type == 'hollow') {
        .button.#{$btn-name} {
            &.hollow {
                border-color: lighten($btn-color, 20%);
                background-color: transparent;
                color: lighten($btn-color, 5%);
                &:before {
                    color: lighten($btn-color, 5%);
                }
                &:hover, &:active, &:focus {
                    border-color: lighten($btn-color, 20%);
                    color:lighten($btn-color, 20%);
                    &:before {
                        color:lighten($btn-color, 20%);
                    }
                }
                &:focus {
                    box-shadow: none;
                    background-color: rgba(get-color(#{$btn-color}), 0.15);
                }
            }
        }
    } @else {
        .button.#{$btn-name} {
            background-color: $btn-color;
            border-color: $btn-color;
            &:hover, &:active, &:focus {
                background-color: scale-color($btn-color, $lightness: $button-background-hover-lightness);
                border-color: scale-color($btn-color, $lightness: $button-background-hover-lightness);
            }
            &:focus {

            }
        }
    }
}

//For neutral color button (not included in branded color palette, doesn't have solid button type)
@mixin neut-btn($btn-name, $btn-color, $btn-type: 'hollow') {
    @if ($btn-type == 'flat') {
        .button.#{$btn-name} {
            color: $btn-color;
            &.flat {
                &:hover {
                    @if ($btn-name == 'medium-gray') {
                        color: lighten($btn-color, 20%);
                    } @else {
                        color: darken($btn-color, 20%);
                    }
                }
            }
        }
    } @else {
        .button.#{$btn-name} {
            @if ($btn-name == 'ui-gray') {
                color: darken($btn-color, 20%);
                border-color: darken($btn-color, 20%);
            } @else {
                color: $btn-color;
                border-color: $btn-color;
            }
            &.hollow {
                background-color: transparent;
                @if ($btn-name == 'ui-gray') {
                    color: $btn-color;
                    border-color: $btn-color;
                    &:before {
                        color: $btn-color;
                    }
                } @else {
                    color: lighten($btn-color, 10%);
                    border-color: lighten($btn-color, 10%);
                    &:before {
                        color: lighten($btn-color, 10%);
                    }
                }
                &:hover, &:active, &:focus {
                    @if ($btn-name == 'ui-gray') {
                        color: darken($btn-color, 20%);
                        border-color: darken($btn-color, 20%);
                        &:before {
                            color: darken($btn-color, 20%);
                        }
                    } @else {
                        color: $btn-color;
                        border-color: $btn-color;
                        &:before {
                            color: $btn-color;
                        }
                    }
                }
                &:focus {
                    box-shadow: none;
                    background-color: rgba($btn-color, 0.15);
                }
            }
        }
    }
}

@mixin link-to-solid-btn {
    display: inline-block;
    vertical-align: middle;
    padding: $button-padding;
    -webkit-appearance: none;
    border: 2px solid $button-background;
    border-radius: $button-radius;
    transition: $button-transition;
    font-size: 1rem;
    line-height: 1;
    text-align: center;
    background-color: $button-background;
    color: $button-color;
    box-shadow: $button-box-shadow;
    position: relative;
    text-shadow: $global-text-shadow;
    &:before {
        font-family: $icomoon-font-family;
        content: $cas-i-logo-icon;
        color: $button-color;
        font-size: rem-calc(22);
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        z-index: -1;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: $button-transition;
        text-shadow: none;
    }
    &:hover {
        background-color: $button-background-hover;
        color: $button-color;
        border-color: $button-background-hover;
        &:before {
            transform: translateY(-30px);
        }
    }
}

@mixin link-to-hollow-btn {
    display: inline-block;
    vertical-align: middle;
    padding: $button-padding;
    -webkit-appearance: none;
    border: 2px solid;
    border-radius: $button-radius;
    transition: $button-hollow-transition;
    font-size: 1rem;
    line-height: 1;
    text-align: center;
    background-color: transparent;
    color: $primary-color;
    cursor: pointer;
    &:before {
        font-family: $icomoon-font-family;
        content: $cas-i-logo-icon;
        color: $button-color;
        font-size: rem-calc(22);
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        z-index: -1;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: $button-transition;
        opacity: 0;
    }
    &:hover, &:active, &:focus {
        color: $button-hollow-hover-color;
        border-color: $button-hollow-hover-color;
        &:before {
            opacity: 1;
            color: $button-hollow-hover-color;
        }
    }
}

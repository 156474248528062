.modal-slide.pr-cookie-left-bar .modal-inner-wrap {
  position: relative;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  max-width: 28.125rem;
  font-size: 88%;
}
.modal-slide.pr-cookie-left-bar .pr-cookie-close {
  top: 0;
  right: 0;
}
.modal-slide.pr-cookie-left-bar .modal-content {
  position: relative;
}
.modal-slide.pr-cookie-left-bar .pr-actions-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5rem 0 2rem;
}
.modal-slide.pr-cookie-left-bar .button.small {
  font-size: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pr-cookie-title, .pr-cookie-manage-title {
  font-family: "Roboto Slab", Cambria, "Times New Roman", serif;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.message-system-list li {
  margin-bottom: 1.5rem;
}
.message-system-list li:last-child {
  margin-bottom: 0;
}

.pr-cookie-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.pr-cookie-option .option-label {
  font-size: 1.0625rem;
}
.pr-cookie-option .option-status {
  font-size: 0.875rem;
  color: #00aa61;
}

.pr-cookie-details {
  color: #d56d18;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0.1875rem;
  font-size: 100%;
  margin-top: 0.5rem;
}
.pr-cookie-details.button.flat {
  border-radius: 0;
  border-bottom: 2px dotted #b3b5b9;
}
.pr-cookie-details.button.flat:hover {
  border-bottom-color: transparent;
}
.pr-cookie-details:hover {
  border: 0;
  color: #e67921;
}

.pr-cookie-switch .switch-input {
  height: 1.5rem;
  width: 3rem;
  left: 0;
  margin-bottom: 0;
}
.pr-cookie-switch .switch-input:checked + .switch-paddle:after {
  left: 1.625rem;
  background-color: #3c6d0d;
}
.pr-cookie-switch .switch-active-icon, .pr-cookie-switch .switch-inactive-icon {
  font-size: 0.75rem;
}
.pr-cookie-switch .switch-active-icon {
  color: #3c6d0d;
}
.pr-cookie-switch .switch-paddle {
  height: 1.5rem;
  width: 3rem;
}
.pr-cookie-switch .switch-paddle:after {
  width: 1.1875rem;
  height: 1.1875rem;
  margin-top: -0.5625rem;
}

.button.medium-gray {
  color: #676d7f;
  border-color: #676d7f;
}
.button.medium-gray.hollow {
  background-color: transparent;
  color: #818798;
  border-color: #818798;
}
.button.medium-gray.hollow:before {
  color: #818798;
}
.button.medium-gray.hollow:hover, .button.medium-gray.hollow:active, .button.medium-gray.hollow:focus {
  color: #676d7f;
  border-color: #676d7f;
}
.button.medium-gray.hollow:hover:before, .button.medium-gray.hollow:active:before, .button.medium-gray.hollow:focus:before {
  color: #676d7f;
}
.button.medium-gray.hollow:focus {
  box-shadow: none;
  background-color: rgba(103, 109, 127, 0.15);
}

.pr-cookie-view-top .title {
  display: block;
  margin: 1rem 0 0;
  font-family: "Roboto Slab", Cambria, "Times New Roman", serif;
  font-size: 1.5625rem;
}

.pr-cookie-view-title {
  font-size: 1.375rem;
  margin-bottom: 1rem;
}

.pr-cookie-details-info summary {
  cursor: pointer;
}
.pr-cookie-details-info .pr-cookie-details-title {
  display: inline-block;
  margin-top: 0;
}
.pr-cookie-details-info .pr-cookie-group-view {
  margin-left: 1rem;
  color: #d56d18;
}
.pr-cookie-details-info .pr-cookie-group {
  margin: 1.5rem 0;
  border: 1px solid #b3b5b9;
}
.pr-cookie-details-info .pr-cookie-group-row {
  border-bottom: 1px solid #b3b5b9;
  display: flex;
  align-items: flex-start;
}
.pr-cookie-details-info .pr-cookie-group-row:last-child {
  border-bottom: 0;
}
.pr-cookie-details-info .pr-cookie-property {
  width: 22%;
  padding: 0.3125rem 0.625rem;
  font-weight: 700;
}
.pr-cookie-details-info .pr-cookie-property-value {
  padding: 0.3125rem 0.625rem;
  border-left: 1px solid #b3b5b9;
}